import { AppMetadata, AppName, OemPartnerNames } from './types';
import MobileLogo from '../assets/product-logos/mobile-logo';
import MobileTennantLogo from '../assets/product-logos/mobile-tennant-logo';
import PortalLogo from '../assets/product-logos/portal-logo';
import LearningCenterLogo from '../assets/product-logos/learning-center-logo';
import VirtualToursLogo from '../assets/product-logos/virtual-tours-logo';
import AutoroboLogo from '../assets/product-logos/autorobo-logo';
import EmailReportsLogo from '../assets/product-logos/email-reports-logo';
import UserManagementLogo from '../assets/product-logos/user-management-logo';
import SenseToolLogo from '../assets/product-logos/sense-tool-logo';
import HealthStatusLogo from '../assets/product-logos/health-status-logo';
import { ENV_DOMAIN } from '../auth/auth-consts';
import { SxProps } from '@mui/material/styles';

export const PRIVACY_POLICY = 'https://www.braincorp.com/data-privacy/';

export const MAX_VIEW_WIDTH = 1280;
export const MIN_VIEW_WIDTH = 280;
export const HEADER_HEIGHT = 100;
export const FOOTER_HEIGHT = 25;

export const APP_NAMES = [
  'mobile',
  'mobileTennant',
  'portal',
  'learningCenter',
  'virtualTours',
  'autorobo',
  'emailReports',
  'userManagement',
  'senseTool',
  'healthStatus',
] as const;

const LOGO_STYLES: SxProps = {
  width: 'auto',
  height: '36px',
};

/**
 * NOTE: App cards are sorted alphabetically by the app name
 * This means that `mobileTennant` will be adjacent to `mobile` in the app grid, despite the `name` being very different
 */
export const APP_CARDS_META: Record<AppName, AppMetadata> = {
  mobile: {
    logo: <MobileLogo sx={LOGO_STYLES} />,
    name: 'Mobile',
    description:
      'On-the-go control and insights with our user-friendly mobile app',
    // the QR code URL to the app store
    // the logic to handle the QR code popover is in `<AppCard />`
    destination: 'https://qrco.de/bcizPZ',
    permission: 'insight.subscription.get',
  },
  mobileTennant: {
    logo: <MobileTennantLogo sx={LOGO_STYLES} />,
    name: 'Tennant Robotics',
    description:
      'On-the-go control and insights with the user-friendly Tennant Robotics mobile app',
    // the QR code URL to the app store
    // the logic to handle the QR code popover is in `<AppCard />`
    destination: 'https://qrco.de/bcizPZ', // todo: new qrcode for tennant app
    permission: 'tennant.mobile',
  },
  portal: {
    logo: <PortalLogo sx={LOGO_STYLES} />,
    name: 'Portal',
    description: 'Cloud-based reporting portal for streamlined operations',
    destination: `https://portal.brainos.${ENV_DOMAIN}`,
    permission: 'portal.dashboard.get',
  },
  learningCenter: {
    logo: <LearningCenterLogo sx={LOGO_STYLES} />,
    name: 'Learning Center',
    description:
      'Master your robot by exploring our interactive Learning Center',
    destination: `https://learn.brainos.${ENV_DOMAIN}`,
    permission: true,
  },
  virtualTours: {
    logo: <VirtualToursLogo sx={LOGO_STYLES} />,
    name: 'Virtual Tours',
    description: 'Remotely explore and manage your location, anytime, anywhere',
    destination: `https://tours.brainos.${ENV_DOMAIN}`,
    permission: 'tour.site.get',
  },
  autorobo: {
    logo: <AutoroboLogo sx={LOGO_STYLES} />,
    name: 'Autorobo',
    description:
      'Monitoring performance and health via comprehensive fleet dashboards',
    destination: `https://autorobo.brainos.${ENV_DOMAIN}`,
    permission: 'autorobo.modules.home',
  },
  emailReports: {
    logo: <EmailReportsLogo sx={LOGO_STYLES} />,
    name: 'Email Reports',
    description:
      'Easily configure and manage customer email report subscriptions',
    destination: `https://emails.brainos.${ENV_DOMAIN}`,
    permission: 'sheep.job.get',
  },
  userManagement: {
    logo: <UserManagementLogo sx={LOGO_STYLES} />,
    name: 'User Management',
    description:
      'Seamlessly control user access to robots and BrainOS applications',
    destination: `https://admin.brainos.${ENV_DOMAIN}`,
    permission: 'iam.user.get',
  },
  senseTool: {
    logo: <SenseToolLogo sx={LOGO_STYLES} />,
    name: 'Sense Tools',
    description:
      'Enhance our Shelf Scanning solution through real-time image QA',
    destination: `https://shadow.brainos.${ENV_DOMAIN}`,
    permission: 'shadow.site.list',
  },
  healthStatus: {
    logo: <HealthStatusLogo sx={LOGO_STYLES} />,
    name: 'Health Status',
    description: 'View real-time system performance on BrainOS Apps',
    destination: `https://status.brainos.${ENV_DOMAIN}`,
    // manually only show for internal tenants for now
    permission: false,
  },
};

export const OEM_PARTNER_LOGO_FILES: Record<OemPartnerNames, string> = {
  dane: '/oem-partners/dane-logo-white.svg',
  karcher: '/oem-partners/karcher-logo-white.svg',
  minuteman: '/oem-partners/minuteman-logo-white.svg',
  nilfisk: '/oem-partners/nilfisk-logo-white.svg',
  tennant: '/oem-partners/tennant-logo-white.svg',
};
