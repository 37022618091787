import { useTheme } from '@mui/styles';
import { blueGrey, grey } from '@mui/material/colors';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useAuth } from '../../auth/auth-context';
import { ENV_DOMAIN, LOGIN_URL, EMAIL_KEY } from '../../auth/auth-consts';
import Loading from '../../shared/loading';
import BrainCorpLogo from '../../assets/product-logos/brain-corp-logo';
import { styled } from '@mui/material/styles';

const Login = () => {
  const [{ authLoading }] = useAuth();
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up('sm'));

  const redirectToLogin = (): void => {
    // if an SSO user was previously logged in and their token expired,
    // we want to add their email to the login URL to help them log in faster
    const tokenEmail = window.sessionStorage.getItem(EMAIL_KEY) || '';
    window.location.href = `${LOGIN_URL}&login_type=web${
      tokenEmail && `&email=${tokenEmail}`
    }`;

    return;
  };

  return (
    <>
      <StyledContainer>
        {authLoading ? (
          <Loading />
        ) : (
          <LoginContainer>
            <Left>
              <BrainCorpLogo
                sx={{ width: 'auto', height: `${isWide ? '50%' : '60%'}` }}
                lightTheme
              />
              <MessageContainer>
                <StyledSpan sx={{ fontSize: '.8em' }}>
                  Welcome to Brain Corp's
                </StyledSpan>
                <StyledSpan
                  sx={{
                    fontSize: '1em',
                    margin: theme.spacing(0.5, 0),
                  }}
                >
                  BrainOS Hub
                </StyledSpan>
              </MessageContainer>
              <Button
                data-testid="signIn"
                onClick={redirectToLogin}
                fullWidth
                variant="contained"
                // TODO: this is using Braincorp primary button for "light theme" override.
                //  When we have a unified design language, we won't need a special override here.
                sx={{ backgroundColor: '#F47521' }}
              >
                <strong>Sign In</strong>
              </Button>
            </Left>
            <Right>
              <VideoContainer autoPlay loop muted>
                <source
                  src={`https://assets.brainos.${ENV_DOMAIN}/videos/brainos.mp4`}
                  type="video/mp4"
                />
              </VideoContainer>
            </Right>
          </LoginContainer>
        )}
      </StyledContainer>
      <StyledIFrame src={LOGIN_URL} title="login" />
    </>
  );
};

const StyledContainer = styled(Box)(() => ({
  flexDirection: 'column',
  height: '100vh',
  minHeight: 600,
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  overflow: 'hidden',
  backgroundColor: grey[100],
}));

const LoginContainer = styled('div')(({ theme }) => ({
  borderRadius: 4,
  boxShadow: '0 2px 14px 0 rgba(0, 0, 0, .14)',
  minHeight: 337,
  height: 362,
  margin: 'auto 0',
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.primary.contrastText,
}));

const Left = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  minWidth: 220,
  padding: theme.spacing(4),
}));

const MessageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(4, 0),
  color: blueGrey[400],
}));

const Right = styled('div')(({ theme }) => ({
  width: 478,
  // hide the video if the screen is too small
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  backgroundColor: '#53B0B9', // same color as video
}));

const StyledSpan = styled('span')(() => ({}));

const VideoContainer = styled('video')(() => ({
  paddingLeft: 1,
  height: 358,
  width: '100%',
}));

const StyledIFrame = styled('iframe')(() => ({ display: 'none' }));

export default Login;
