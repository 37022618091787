import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import * as englishFallback from '../i18n/EN.json';

type OptionsType = {
  [key: string]: (chunks: string) => ReactNode;
};
const useTranslateMessage = (): ((
  id: string,
  options?: OptionsType
) => string | ReactNode) => {
  const { formatMessage } = useIntl();
  const fallback = englishFallback as { [key: string]: string };
  const translateMessage = (
    id: string,
    options?: OptionsType // used for rich text formatting https://formatjs.io/docs/react-intl/api#usage
  ): string | ReactNode => {
    return formatMessage({ id }, { defaultMessage: fallback[id], ...options });
  };
  return translateMessage;
};

export default useTranslateMessage;
