import { LocaleItem, LocaleList } from './localization-types';

export const LOCALE_EN: LocaleItem = {
  label: 'United States',
  value: 'en-US',
  flag: '🇺🇸',
};

const LOCALE_LIST: LocaleList = [
  {
    label: 'AMERICAS',
  },
  LOCALE_EN,
  // todo: enable other locales
  // {
  //   label: 'Brasil',
  //   value: 'pt-BR',
  //   flag: '🇧🇷',
  // },
  // {
  //   label: 'México',
  //   value: 'es-MX',
  //   flag: '🇲🇽',
  // },
  // {
  //   label: 'ASIA_PACIFIC',
  // },
  // {
  //   label: 'Australia',
  //   value: 'en-AU',
  //   flag: '🇦🇺',
  // },
  // {
  //   label: '日本',
  //   value: 'ja-JP',
  //   flag: '🇯🇵',
  // },
  // {
  //   label: '대한민국',
  //   value: 'ko-KR',
  //   flag: '🇰🇷',
  // },
  // {
  //   label: 'EUROPE',
  // },
  // {
  //   label: 'Danmark',
  //   value: 'da-DK',
  //   flag: '🇩🇰',
  // },
  // {
  //   label: 'Deutschland',
  //   value: 'de-DE',
  //   flag: '🇩🇪',
  // },
  // {
  //   label: 'España',
  //   value: 'es-ES',
  //   flag: '🇪🇸',
  // },
  // {
  //   label: 'France',
  //   value: 'fr-FR',
  //   flag: '🇫🇷',
  // },
  // {
  //   label: 'Italia',
  //   value: 'it-IT',
  //   flag: '🇮🇹',
  // },
  // {
  //   label: 'Nederland',
  //   value: 'nl-NL',
  //   flag: '🇳🇱',
  // },
  // {
  //   label: 'Portugal',
  //   value: 'pt-PT',
  //   flag: '🇵🇹',
  // },
  // {
  //   label: 'Suomi',
  //   value: 'fi-FI',
  //   flag: '🇫🇮',
  // },
  // {
  //   label: 'Sverige',
  //   value: 'sv-SE',
  //   flag: '🇸🇪',
  // },
  // {
  //   label: 'United Kingdom',
  //   value: 'en-GB',
  //   flag: '🇬🇧',
  // },
];

export default LOCALE_LIST;
