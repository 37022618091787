import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ENV_DOMAIN } from '../auth/auth-consts';
import { getOrRefreshIdToken } from '../auth/auth-utils';

const subenv = process.env.REACT_APP_SUBENV || '';

const apiEndpoint =
  subenv === ''
    ? `https://user-api.brainos.${ENV_DOMAIN}`
    : `https://user-api.${subenv}.brainos.${ENV_DOMAIN}`;

const createInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.request.use(
    // @ts-ignore todo: fix why I need to ignore this
    async config => {
      const token = await getOrRefreshIdToken();
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    },
    error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
};

const params: AxiosRequestConfig = {
  baseURL: apiEndpoint,
  headers: {
    accept: 'application/json',
  },
  timeout: 20000, // default timeout for all api requests
};
const brainOsApiInstance = axios.create(params);
createInterceptor(brainOsApiInstance);

const useBrainOSApi = (): { brainOsApiInstance: AxiosInstance } => {
  // NOTE: axios instance creation and interceptors need to be defined outside the scope of the hook
  // otherwise, the interceptor will fire nearly constantly and hinder app performance
  return {
    brainOsApiInstance,
  };
};

export default useBrainOSApi;
