import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LearningCenterLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_2109_54624" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 22.118C26.7202 20.2205 28.5 17.0681 28.5 13.5C28.5 7.70101 23.799 3 18 3C12.201 3 7.5 7.70101 7.5 13.5C7.5 17.0681 9.27977 20.2205 12 22.118V24C12 25.6569 13.3431 27 15 27H21C22.6569 27 24 25.6569 24 24V22.118Z"
      />
    </mask>
    <path
      d="M24 22.118L22.2837 19.6574L21 20.5528V22.118H24ZM12 22.118H15V20.5528L13.7163 19.6574L12 22.118ZM25.5 13.5C25.5 16.0461 24.2341 18.297 22.2837 19.6574L25.7163 24.5785C29.2064 22.1441 31.5 18.0901 31.5 13.5H25.5ZM18 6C22.1421 6 25.5 9.35786 25.5 13.5H31.5C31.5 6.04416 25.4558 0 18 0V6ZM10.5 13.5C10.5 9.35786 13.8579 6 18 6V0C10.5442 0 4.5 6.04416 4.5 13.5H10.5ZM13.7163 19.6574C11.7659 18.297 10.5 16.0461 10.5 13.5H4.5C4.5 18.0901 6.7936 22.1441 10.2837 24.5785L13.7163 19.6574ZM15 24V22.118H9V24H15ZM15 24H9C9 27.3137 11.6863 30 15 30V24ZM21 24H15V30H21V24ZM21 24V30C24.3137 30 27 27.3137 27 24H21ZM21 22.118V24H27V22.118H21Z"
      fill="white"
      mask="url(#path-1-inside-1_2109_54624)"
    />
    <path
      d="M13.5 30H22.5"
      stroke="#FF7C00"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default LearningCenterLogo;
