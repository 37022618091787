const isDeployed = document.location.hostname.includes('brainos.');
export const ENV_DOMAIN =
  document.location.hostname === 'www.brainos.com' ? 'com' : 'dev';
const WEBAPP_URL = `${document.location.origin}/login`;
export const LOGIN_URL = isDeployed
  ? `https://login.brainos.${ENV_DOMAIN}?redirect_uri=${WEBAPP_URL}`
  : `http://localhost:3030?redirect_uri=${WEBAPP_URL}`;

export const UNAUTHENTICATED_MSG = 'unauthenticated';
export const REVISIT_KEY = `brainos-hub-revisit-${window.location.hostname}`;
export const API_KEY = `brainos-hub-session-key-${window.location.hostname}`;
export const REFRESH_KEY = `brainos-hub-session-refresh-${window.location.hostname}`;
export const ID_TOKEN_KEY = `brainos-hub-session-id-${window.location.hostname}`;
export const EMAIL_KEY = `brainos-hub-session-email-${window.location.hostname}`;
