import { Dispatch, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthDispatchType } from './auth-types';
import {
  API_KEY,
  ID_TOKEN_KEY,
  LOGIN_URL,
  REFRESH_KEY,
  REVISIT_KEY,
  EMAIL_KEY,
} from './auth-consts';
import { handleRevisit } from './auth-utils';

const useLogout = (
  authDispatch: Dispatch<AuthDispatchType>,
  shouldStoreRedirect?: boolean
) => {
  const [shouldLogout, setShouldLogout] = useState(false);
  const stableDispatch = useCallback(authDispatch, [authDispatch]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (shouldLogout) {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = `${LOGIN_URL}&signout=1`;
      iframe.title = 'logout';
      document.documentElement.append(iframe);
    }
  }, [shouldLogout]);

  useEffect(() => {
    const handler = (event: MessageEvent): void => {
      // wait for iframe to register as unauthenticated
      if (event.data === 'unauthenticated') {
        sessionStorage.removeItem(ID_TOKEN_KEY);
        // because user manually logged out, remove email from session storage
        sessionStorage.removeItem(EMAIL_KEY);
        sessionStorage.removeItem(API_KEY);
        sessionStorage.removeItem(REFRESH_KEY);
        stableDispatch({
          type: 'SET_IS_AUTHENTICATED',
          payload: false,
        });
        shouldStoreRedirect &&
          handleRevisit(
            location.pathname,
            location.search,
            localStorage.getItem(REVISIT_KEY)
          );
        setShouldLogout(false);
        navigate('/login');
      }
    };
    shouldLogout && window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }, [shouldLogout, shouldStoreRedirect, location, stableDispatch, navigate]);

  return () => {
    setShouldLogout(true);
  };
};

export default useLogout;
