import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SenseToolLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 12V9C6 7.34315 7.34315 6 9 6H12"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M24 6L27 6C28.6569 6 30 7.34315 30 9L30 12"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M30 24L30 27C30 28.6569 28.6569 30 27 30L24 30"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M12 30L9 30C7.34315 30 6 28.6569 6 27L6 24"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      fill="none"
    />
    <rect
      x="13.5"
      y="10.5"
      width="9"
      height="15"
      rx="1.5"
      stroke="#FF7C00"
      strokeWidth="3"
      fill="none"
    />
  </SvgIcon>
);

export default SenseToolLogo;
