import React, { FC, useEffect } from 'react';
import { useAuth } from '../../auth/auth-context';
import useLogout from '../../auth/use-logout';
import Loading from '../../shared/loading';

const Logout: FC = () => {
  const [, authDispatch] = useAuth();
  const logout = useLogout(authDispatch);

  useEffect(() => {
    logout();
  }, [logout]);

  return <Loading />;
};

export default Logout;
