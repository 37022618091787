import React, { createContext, useContext, useReducer } from 'react';

import { LOCALE_EN } from './locale-list';
import {
  InitialLocalizationDispatchType,
  LocaleState,
} from './localization-types';
import { getLocaleItem } from './localization-utils';
import { FCC } from '../../utils/types';

const url = new URLSearchParams(window.location.search);
const urlLocale = url.get('locale');
const requestedLocale = urlLocale || navigator?.language || 'en-US';

const initialLocalizationState = {
  isEmbedded: false,
  locale: getLocaleItem(requestedLocale),
};

const LocalizationStateContext = createContext<LocaleState>(
  initialLocalizationState
);
const LocalizationDispatchContext = createContext<any>(undefined);

const localizationReducer = (
  state: LocaleState,
  { action, payload }: InitialLocalizationDispatchType
) => {
  switch (action) {
    case 'SET':
      return {
        ...state,
        ...payload,
      };
    case 'RESET':
      return {
        isEmbedded: false,
        locale: { ...LOCALE_EN },
      };
    default:
      return state;
  }
};
const LocalizationProvider: FCC = ({ children }) => {
  const [state, dispatch] = useReducer(
    localizationReducer,
    initialLocalizationState
  );
  return (
    <LocalizationStateContext.Provider value={state}>
      <LocalizationDispatchContext.Provider
        value={dispatch as (value: InitialLocalizationDispatchType) => void}
      >
        {children}
      </LocalizationDispatchContext.Provider>
    </LocalizationStateContext.Provider>
  );
};

const useLocalizationState = () => {
  const context = useContext(LocalizationStateContext);
  if (context === undefined) {
    throw new Error(
      'useLocalizationState must be used within a LocalizationProvider'
    );
  }
  return context;
};

const useLocalizationDispatch = () => {
  const context = useContext(LocalizationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'LocalizationDispatchContext must be used within a LocalizationProvider'
    );
  }
  return context;
};

const useLocalization = () => {
  return [useLocalizationState(), useLocalizationDispatch()];
};

export {
  LocalizationProvider,
  useLocalizationState,
  useLocalizationDispatch,
  useLocalization,
  LocalizationStateContext,
  initialLocalizationState,
};
