import { Dispatch, useCallback, useEffect, useState } from 'react';
import { RawUser, AuthStateType, AuthDispatchType } from './auth-types';
import {
  getUserFromToken,
  refreshIdToken,
  saveTokensToStorage,
} from './auth-utils';
import { UNAUTHENTICATED_MSG } from './auth-consts';
import getUserPermissions from './permissions-api';
import useBrainOSApi from '../api/use-brainos-api';

export const useAuthenticateApp = (
  dispatch: Dispatch<AuthDispatchType>,
  state: AuthStateType
) => {
  const [rawUser, setRawUser] = useState<RawUser | null>(null);
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const { isAuthenticated } = state;
  const { brainOsApiInstance } = useBrainOSApi();

  useEffect(() => {
    const handler = (event: MessageEvent): void => {
      if (event.data === UNAUTHENTICATED_MSG) {
        stableDispatch({
          type: 'SET_AUTH_LOADING',
          payload: false,
        });
        return;
      }
      if (typeof event.data === 'string' && event.data[0] === '{') {
        try {
          const userJson = JSON.parse(event.data);
          setRawUser(userJson);
        } catch (err) {
          console.error(err);
        }
      }
    };
    window.addEventListener('message', handler);
    // clean up
    return () => window.removeEventListener('message', handler);
  }, [stableDispatch]);

  useEffect(() => {
    if (rawUser && !isAuthenticated) {
      const fetchTokens = async (): Promise<{
        idToken: string;
        refreshToken: string;
        apiKey: string;
      }> => {
        const {
          apiKey,
          stsTokenManager: { refreshToken },
        } = rawUser;
        const { id_token: idToken, refresh_token: newRefreshToken } =
          await refreshIdToken(refreshToken, apiKey);
        return {
          idToken,
          refreshToken: newRefreshToken,
          apiKey,
        };
      };

      const authenticate = async () => {
        try {
          const { idToken, refreshToken, apiKey } = await fetchTokens();
          // set tokens to storage for use by apis
          saveTokensToStorage(idToken, refreshToken, apiKey);
          const userInfo = getUserFromToken(idToken);

          stableDispatch({
            type: 'SET_USER',
            payload: userInfo,
          });

          // get permissions
          const permissions = await getUserPermissions(
            userInfo.email,
            brainOsApiInstance
          );
          stableDispatch({
            type: 'SET_PERMISSIONS',
            payload: permissions,
          });

          stableDispatch({
            type: 'SET_IS_AUTHENTICATED',
            payload: true,
          });
          // clean up state
          setRawUser(null);
        } catch (error) {
          console.error('Login error', error);
        }
      };
      authenticate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableDispatch, rawUser, isAuthenticated]);
};
