import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EmailReportsLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="31.5"
      y="7.5"
      width="21"
      height="27"
      rx="4.5"
      transform="rotate(90 31.5 7.5)"
      stroke="white"
      strokeWidth="3"
      fill="none"
    />
    <path
      d="M7.5 15.5C7.5 16.0928 7.8491 16.6299 8.39079 16.8707L14.954 19.7877C16.8932 20.6495 19.1068 20.6495 21.0461 19.7877L27.6092 16.8707C28.1509 16.63 28.5 16.0928 28.5 15.5C28.5 14.4145 27.3827 13.6884 26.3908 14.1293L19.8276 17.0462C18.6641 17.5634 17.3359 17.5634 16.1724 17.0462L9.60921 14.1293C8.61728 13.6884 7.5 14.4145 7.5 15.5Z"
      fill="#FF7C00"
    />
  </SvgIcon>
);

export default EmailReportsLogo;
