// Copyright 2022 Brain Corporation. All rights reserved. Brain Corporation proprietary and confidential.
// ALL ACCESS AND USAGE OF THIS SOURCE CODE IS STRICTLY PROHIBITED WITHOUT EXPRESS WRITTEN APPROVAL FROM BRAIN CORPORATION.

import {
  Box,
  Divider,
  Link,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocalizationState } from '../../providers/localization-provider/localization-context';
import useTranslateMessage from '../../hooks/use-translate-message';
import {
  FOOTER_HEIGHT,
  MIN_VIEW_WIDTH,
  PRIVACY_POLICY,
} from '../../utils/consts';

const TERMS_OF_SERVICE_URL =
  'https://www.braincorp.com/legal/brain-portal-website-app-terms-of-service';

const Footer = () => {
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up('sm'));
  const translateMessage = useTranslateMessage();

  const { isEmbedded } = useLocalizationState();
  const portalVersion = process.env.REACT_APP_VERSION || '';

  return (
    // DOM is stupid and doesn't accept boolean as custom styled param
    <FooterLayout embedded={isEmbedded ? 1 : 0}>
      <Typography variant="caption" textAlign="center" sx={{ mr: 1, ml: 1 }}>
        &copy; {new Date().getFullYear()} Brain Corporation
      </Typography>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ bgcolor: 'secondary.contrastText' }}
      />
      {isWide && (
        <>
          <Typography
            variant="caption"
            textAlign="center"
            sx={{ mr: 1, ml: 1 }}
          >
            Powered by BrainOS&reg;
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ bgcolor: 'secondary.contrastText' }}
          />
        </>
      )}
      <Link
        data-testid={'termsOfServiceFooter'}
        href={TERMS_OF_SERVICE_URL}
        underline={'hover'}
        target="_blank"
        sx={{ color: 'common.white', display: 'flex' }}
      >
        <Typography variant="caption" textAlign="center" sx={{ mr: 1, ml: 1 }}>
          {translateMessage('TERMS_OF_SERVICE')}
        </Typography>
      </Link>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ bgcolor: 'secondary.contrastText' }}
      />
      <Link
        data-testid={'privacyPolicyFooter'}
        href={PRIVACY_POLICY}
        underline={'hover'}
        target="_blank"
        sx={{ color: 'common.white', display: 'flex' }}
      >
        <Typography variant="caption" textAlign="center" sx={{ mr: 1, ml: 1 }}>
          {translateMessage('PRIVACY_POLICY')}
        </Typography>
      </Link>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ bgcolor: 'secondary.contrastText' }}
      />
      <Typography
        data-testid={'footerVersion'}
        variant="caption"
        textAlign="center"
        sx={{ mr: 1, ml: 1 }}
      >
        {portalVersion}
      </Typography>
    </FooterLayout>
  );
};

const FooterLayout = styled(Box)<{ embedded: number }>(
  ({ theme, embedded }) => ({
    position: 'fixed',
    bottom: 0,
    display: embedded ? 'none' : 'flex',
    width: '100%',
    minWidth: MIN_VIEW_WIDTH,
    height: FOOTER_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.default,
    // keep on bottom of the page for mobile
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  })
);

export default Footer;
