import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HealthStatusLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.51475 20.25L18 28.7353L26.4853 20.25H30.7248C30.7248 20.2519 30.7241 20.2538 30.7226 20.2553L30.3733 20.6046L30.3546 20.6233L20.1213 30.8566C18.9497 32.0282 17.0503 32.0282 15.8787 30.8566L5.6457 20.6236L5.62639 20.6043L5.2774 20.2553C5.27594 20.2538 5.2752 20.2519 5.27519 20.25H9.51475ZM30 14.25H33C33 9.27944 28.9706 5.25 24 5.25C21.7025 5.25 19.6061 6.11089 18.0157 7.52769C18.0127 7.53034 18.0082 7.53022 18.0053 7.52739C18.0024 7.52445 17.9976 7.52445 17.9947 7.52739C17.9918 7.53022 17.9873 7.53034 17.9843 7.52769C16.3939 6.11089 14.2975 5.25 12 5.25C7.02944 5.25 3 9.27944 3 14.25C3 14.25 3 14.25 3 14.25H6C6 14.25 6 14.25 6 14.25C6 10.9363 8.68629 8.25 12 8.25C13.5336 8.25 14.9266 8.82148 15.9888 9.76773C16.5632 10.2795 17.2826 10.5322 18 10.5296C18.7174 10.5322 19.4368 10.2795 20.0112 9.76773C21.0734 8.82148 22.4664 8.25 24 8.25C27.3137 8.25 30 10.9363 30 14.25Z"
      fill="white"
    />
    <path
      d="M4.5 17.25H12.5611C13.1048 17.25 13.5707 16.8612 13.668 16.3263L14.1128 13.8797C14.3271 12.7012 15.9898 12.6293 16.305 13.785L18.5679 22.0825C18.8567 23.1413 20.3355 23.2023 20.7106 22.1709L22.2307 17.9905C22.3924 17.546 22.8149 17.25 23.288 17.25H31.5"
      stroke="#FF7C00"
      strokeWidth="3"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);

export default HealthStatusLogo;
