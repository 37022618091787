import { useEffect, useMemo, useState } from 'react';
import Home from './routes/home/home';
import ErrorPage from './shared/error-page';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { useLocalization } from './providers/localization-provider/localization-context';
import { IntlProvider } from 'react-intl';
import {
  fetchI18nMessages,
  i18nMessageList,
} from './providers/localization-provider/localization-utils';
import { THEME } from './utils/theme';
import { useAuth } from './auth/auth-context';
import { REVISIT_KEY } from './auth/auth-consts';
import Layout from './shared/layout/layout';
import Logout from './routes/logout/logout';
import Login from './routes/login/login';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const [translationMessages, setTranslationMessages] =
    useState<i18nMessageList>();
  const [{ locale }] = useLocalization();
  const [{ isAuthenticated }] = useAuth();
  const [authRedirect, setAuthRedirect] = useState<string | null>(null);

  useEffect(() => {
    // set redirect link as local state on component mount
    //  so that we can remove the sessionStorage data (cleanup) without affecting frontend code
    setAuthRedirect(sessionStorage.getItem(REVISIT_KEY));
  }, []);

  useEffect(() => {
    const applyTranslations = async () => {
      const messages = await fetchI18nMessages(locale.value);
      setTranslationMessages(messages);
    };
    applyTranslations();
  }, [locale]);

  // note: the downside of router inside React is that it's not static
  // todo: going forward from other domain to app works, but it erases further history forward
  const router = useMemo(() => {
    return createBrowserRouter([
      {
        path: '/logout',
        element: <Logout />,
        errorElement: <ErrorPage />,
        // maybe move logic to loader?
      },
      {
        path: '/login',
        element: <Login />,
        errorElement: <ErrorPage />,
        loader: async () => {
          if (isAuthenticated) {
            // remove revisit key from local storage upon successful login/redirect
            sessionStorage.removeItem(REVISIT_KEY);
            return redirect(authRedirect || '/');
          }
          return null;
        },
      },
      {
        // Main Page and its children
        // NOTE: the no permissions are necessary to access Hub, only that the user is authenticated
        path: '/',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [{ path: '/', element: <Home /> }],
        loader: async () => {
          if (!isAuthenticated) {
            // todo: implement revisit key setting
            // window.sessionStorage.setItem(REVISIT_KEY, `${pathname}${search}`);
            return redirect('/login');
          }
          return null;
        },
      },
      {
        path: '*',
        element: null,
        errorElement: <ErrorPage />,
        loader: () => {
          return redirect('/');
        },
      },
    ]);
    // important, add only the necessary dependencies, avoid Objects and Arrays
  }, [isAuthenticated, authRedirect]);

  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <IntlProvider locale={locale.value} messages={translationMessages}>
        <RouterProvider router={router} />
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
