import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react';
import { AuthDispatchType, AuthStateType } from './auth-types';
import { useAuthenticateApp } from './use-authenticate-app';

type AuthProviderProps = {
  children: ReactNode | null;
  initialState: AuthStateType;
};

export const initialAuthState: AuthStateType = {
  authLoading: true,
  isAuthenticated: false,
  user: {
    email: '',
    tenantId: '',
    uid: '',
  },
  permissions: [],
};

const AuthContext = createContext<AuthStateType>(initialAuthState);
const AuthDispatchContext = createContext<
  Dispatch<AuthDispatchType> | undefined
>(undefined);

const authReducer = (
  state: AuthStateType,
  { type, payload }: AuthDispatchType
) => {
  switch (type) {
    case 'SET_AUTH_LOADING': {
      return { ...state, authLoading: payload };
    }
    case 'SET_IS_AUTHENTICATED': {
      return { ...state, isAuthenticated: payload };
    }
    case 'SET_USER': {
      return { ...state, user: payload };
    }
    case 'SET_PERMISSIONS': {
      return { ...state, permissions: payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const AuthProvider = ({ children, initialState }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  useAuthenticateApp(dispatch, state);
  return (
    <AuthContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch as any}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};

const useAuthState = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthProvider');
  }
  return context;
};

const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within an AuthProvider');
  }
  return context;
};

const useAuth = (): [AuthStateType, Dispatch<AuthDispatchType>] => [
  useAuthState(),
  useAuthDispatch(),
];

export { AuthProvider, useAuth };
