import { createTheme, responsiveFontSizes } from '@mui/material';

export const THEME = responsiveFontSizes(
  createTheme({
    palette: {
      // for now, dark mode is default.
      // refer to MUI docs to enable user-switching of light/dark mode
      mode: 'dark',
      // todo: create diff theme for light/dark mode
      primary: {
        main: '#E54F10',
        // todo: design for primary button is actually using dark color as default, need a "main, light dark" values for dark and light mode
        contrastText: '#fff',
      },
      secondary: {
        main: '#002FA9',
        contrastText: '#fff',
      },
    },
    typography: {
      button: {
        // MUI button text is ALL CAPS by default, this overrides that
        textTransform: 'none',
      },
    },
  })
);
