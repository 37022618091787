import { MessageFormatElement } from 'react-intl';
import * as englishTranslations from '../../i18n/EN.json';
import { LocaleItem, LocaleType } from './localization-types';
import LOCALE_LIST, { LOCALE_EN } from './locale-list';

export type i18nMessageList =
  | Record<string, string>
  | Record<string, MessageFormatElement[]>
  | undefined;

export const fetchI18nMessages = async (
  locale: LocaleType
): Promise<i18nMessageList> => {
  const language = getLanguageFromLocale(locale).toUpperCase();
  try {
    const messages = await import(`../../i18n/${language}.json`);
    return messages.default;
  } catch (error) {
    console.error(error);
    return englishTranslations;
  }
};

export const getLanguageFromLocale = (locale: string): string => {
  return locale.split(/[-_]/)[0]; // language without region code
};

export const getLocaleItem = (locale: string) => {
  const actualLocale = LOCALE_LIST.find(
    l => 'value' in l && l.value === locale
  ) as LocaleItem;
  if (!actualLocale) {
    return (
      (LOCALE_LIST.find(
        l =>
          'value' in l && l.value?.split(/[-_]/)[0] === locale?.split(/[-_]/)[0]
      ) as LocaleItem) ?? LOCALE_EN
    );
  } else {
    return actualLocale;
  }
};
