import { Box, Button, styled, Theme } from '@mui/material';
import { MAX_VIEW_WIDTH, MIN_VIEW_WIDTH } from '../utils/consts';

export const MaxWidthRowLayout = styled(Box, {
  shouldForwardProp: props => props !== 'justify',
})<{ justify?: string }>(({ justify = 'space-between' }) => ({
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  height: '100%',
  minWidth: MIN_VIEW_WIDTH,
  maxWidth: MAX_VIEW_WIDTH,
  alignItems: 'center',
  justifyContent: justify,
  overflow: 'hidden',
}));

// shared styles between root and header components to get them to work together
export const rootAndHeaderStyles = (theme: Theme) => ({
  paddingRight: theme.spacing(10),
  paddingLeft: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
});

export const AppCardButton = styled(Button)<{ target?: string }>(() => ({
  height: '30px',
  borderRadius: '15px',
}));
