import { AxiosInstance } from 'axios';

const IAM_ENDPOINT = '/v1/iam/users';

const getUserPermissions = async (
  email: string | null,
  apiInstance: AxiosInstance
) => {
  try {
    if (email) {
      const { data } = await apiInstance.get<{ permissions: string[] }>(
        `${IAM_ENDPOINT}/${email}/permissions`
      );
      return data.permissions;
    }
    throw new Error('no email provided');
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default getUserPermissions;
