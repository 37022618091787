import { Dispatch, SetStateAction } from 'react';
import {
  Avatar,
  Box,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import LogoutIcon from 'mdi-react/LogoutIcon';
import ShieldLockOutlineIcon from 'mdi-react/ShieldLockOutlineIcon';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { useAuth } from '../../auth/auth-context';
import { PRIVACY_POLICY } from '../../utils/consts';

type HeaderMenuProps = {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
};

const UserMenu = ({ anchorEl, setAnchorEl }: HeaderMenuProps) => {
  const [{ user }] = useAuth();

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleUserMenuClose}
    >
      <MenuItem>
        <StyledListItemIcon>
          <StyledAvatar>{user.email && user.email[0]}</StyledAvatar>
        </StyledListItemIcon>
        <ListItemText>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="body2">{user.tenantId}</Typography>
            <StyledTypography variant="body2">{user.email}</StyledTypography>
          </Box>
        </ListItemText>
      </MenuItem>
      <MenuItem
        onClick={handleUserMenuClose}
        component={Link}
        href={PRIVACY_POLICY}
        target="_blank"
        color="inherit"
        underline="none"
        data-testid="privacyPolicyHeader"
      >
        <StyledListItemIcon>
          <ShieldLockOutlineIcon />
        </StyledListItemIcon>
        <ListItemText>Privacy Policy</ListItemText>
      </MenuItem>
      <Link
        component={RouterLink}
        to={'/logout'}
        color="inherit"
        underline="none"
        data-testid="logout"
      >
        <MenuItem onClick={handleUserMenuClose} sx={{ flex: 1 }}>
          <StyledListItemIcon>
            <LogoutIcon />
          </StyledListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </Link>
    </Menu>
  );
};

const StyledAvatar = styled(Avatar)(() => ({
  height: 30,
  width: 30,
  // backgroundColor: '#18232A',
  textTransform: 'uppercase',
  lineHeight: 'unset', // attempt to center the text avatar vertically
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.active,
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  minWidth: 'auto',
}));

export default UserMenu;
