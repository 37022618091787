import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AutoroboLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="4.5"
      y="16.5"
      width="27"
      height="15"
      rx="7.5"
      stroke="white"
      strokeWidth="3"
      fill="none"
    />
    <rect
      x="18.75"
      y="9.75"
      width="4.5"
      height="1.5"
      transform="rotate(90 18.75 9.75)"
      stroke="white"
      strokeWidth="1.5"
    />
    <rect
      x="15"
      y="4.5"
      width="6"
      height="6"
      rx="3"
      stroke="white"
      strokeWidth="3"
      fill="none"
    />
    <rect
      x="14.25"
      y="21.75"
      width="4.5"
      height="1.5"
      rx="0.75"
      transform="rotate(90 14.25 21.75)"
      stroke="#FF7C00"
      strokeWidth="1.5"
    />
    <rect
      x="23.25"
      y="21.75"
      width="4.5"
      height="1.5"
      rx="0.75"
      transform="rotate(90 23.25 21.75)"
      stroke="#FF7C00"
      strokeWidth="1.5"
    />
  </SvgIcon>
);

export default AutoroboLogo;
