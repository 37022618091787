import { useTheme } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps } from '@mui/material/styles';
import { Paper } from '@mui/material';

type LoadingProps = {
  type?: 'session' | 'module';
  text?: string;
};

const Loading = ({ type = 'session', text }: LoadingProps) => {
  const theme = useTheme();

  const sharedStyles: SxProps = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // same background as the theme
    background: theme.palette.background.default,
  };

  // cover the whole screen
  const sessionStyles: SxProps = {
    ...sharedStyles,
    height: '100vh',
    width: '100vw',
  };

  // cover just a component
  const moduleStyles: SxProps = {
    ...sharedStyles,
    height: '100%',
    width: '100%',
  };

  return (
    <Paper
      sx={() => {
        switch (type) {
          case 'session':
            return sessionStyles;
          case 'module':
            return moduleStyles;
          default:
            return sessionStyles;
        }
      }}
      data-testid="loading"
    >
      {text && <div>{text}</div>}
      <CircularProgress sx={{ color: theme.palette.primary.main }} size={100} />
    </Paper>
  );
};
export default Loading;
