import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

type BrainCorpLogoProps = SvgIconProps & { lightTheme?: boolean };

const BrainCorpLogo = (props: BrainCorpLogoProps) => {
  // default value is white
  const { lightTheme, ...rest } = props;
  const fillColor = lightTheme ? 'black' : 'white';

  return (
    <SvgIcon
      width="86"
      height="40"
      viewBox="0 0 86 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // omit the custom `lightTheme` prop, or else warning will be thrown
      {...rest}
    >
      <path
        d="M25.6084 21.9796H45.947V24.2326H25.6084V21.9796Z"
        // underline remains orange
        fill="#EF6A11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.5023 29.9481C67.4124 29.0446 67.9142 27.8087 67.8915 26.5265C67.8915 23.7371 65.7842 21.7814 63.0707 21.7814C60.3571 21.7814 58.2323 23.7371 58.2323 26.5265C58.2323 29.3158 60.3571 31.3124 63.0707 31.3124C64.3527 31.3443 65.5922 30.8516 66.5023 29.9481ZM63.0531 23.0726C64.9607 23.0726 66.507 24.6189 66.507 26.5265C66.507 28.434 64.9607 29.9803 63.0531 29.9803C61.1456 29.9803 59.5992 28.434 59.5992 26.5265C59.5992 24.6189 61.1456 23.0726 63.0531 23.0726Z"
        fill={fillColor}
      />
      <path
        d="M75.2802 21.965V23.3145C75.1141 23.2941 74.8022 23.2941 74.6361 23.2941C72.2169 23.2941 70.9781 25.1245 70.9781 27.5816V31.0792H69.5558V21.9825H70.9781V24.218C71.7185 22.8714 72.9543 21.8863 74.6361 21.8863C74.8536 21.8787 75.0709 21.9052 75.2802 21.965Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.4389 31.3299C83.8581 31.3299 85.9829 29.4266 85.9829 26.5469C85.9829 23.6672 83.8668 21.761 81.4418 21.7639C80.0364 21.7174 78.6926 22.3417 77.8218 23.4457V21.9883H76.4198V34.23H77.8218V29.6481C78.692 30.7512 80.0345 31.3754 81.4389 31.3299ZM81.2175 22.9997C83.1033 22.9997 84.616 24.3667 84.616 26.5469H84.6247C84.6247 28.7067 83.112 30.0795 81.2175 30.0736C79.3036 30.0187 77.7806 28.4514 77.7806 26.5367C77.7806 24.622 79.3036 23.0547 81.2175 22.9997Z"
        fill={fillColor}
      />
      <path
        d="M55.7607 28.3102C55.2361 29.444 54.1227 30.0182 52.8956 30.0182C51.0273 30.0182 49.5146 28.6687 49.5146 26.5469C49.5146 24.425 51.0273 23.0492 52.8956 23.0492C54.0915 23.0072 55.1971 23.6827 55.7053 24.766H57.2093C56.6468 22.9123 54.8776 21.7551 52.8956 21.7551C50.2992 21.8192 48.2278 23.9424 48.2278 26.5396C48.2278 29.1367 50.2992 31.2599 52.8956 31.324C54.9067 31.324 56.6847 30.1785 57.1976 28.3131H57.1656L55.7607 28.3102Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16109 20.1346C11.6325 20.1346 14.8648 17.2024 14.8648 12.9033C14.8648 8.60121 11.6296 5.64281 8.13777 5.64281C6.2753 5.64281 4.78298 6.19077 3.61711 7.1497V0H0V19.7236H3.64043V18.6568C4.92847 19.6623 6.52786 20.1851 8.16109 20.1346ZM7.53153 8.79357C9.72336 8.79357 11.3672 10.3821 11.3672 12.9033C11.3672 15.4215 9.71171 16.9838 7.53153 16.9838C6.47085 16.9695 5.46057 16.5289 4.72853 15.7612C3.99648 14.9935 3.6043 13.9634 3.64044 12.9033C3.64044 10.3821 5.58453 8.79357 7.53153 8.79357Z"
        fill={fillColor}
      />
      <path
        d="M25.2732 5.97216L25.0808 9.44937H24.2851C20.9449 9.44937 19.4089 11.4896 19.4089 15.3137V19.7236H15.7947V6.05377H19.4089V8.54582C20.5048 6.95732 22.0933 5.8614 24.3143 5.8614C24.6379 5.84189 24.9625 5.87938 25.2732 5.97216Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4567 19.7236H40.0738V6.05379H36.4567V7.14971C35.3083 6.19078 33.8015 5.64282 31.939 5.64282C28.4676 5.64282 25.2352 8.60413 25.2352 12.9033C25.2352 17.2024 28.4589 20.1346 31.939 20.1346C33.5712 20.1844 35.1695 19.6617 36.4567 18.6568V19.7236ZM32.5685 8.79358C34.5126 8.79358 36.4567 10.3821 36.4567 12.9033C36.4928 13.9629 36.101 14.9926 35.3696 15.7602C34.6382 16.5278 33.6287 16.9688 32.5685 16.9838C30.3767 16.9838 28.7328 15.4245 28.7328 12.9033C28.7328 10.3821 30.3767 8.79358 32.5685 8.79358Z"
        fill={fillColor}
      />
      <path
        d="M46.3433 2.26762C46.242 3.39938 45.2936 4.26665 44.1573 4.26665C43.021 4.26665 42.0726 3.39938 41.9713 2.26762C42.055 1.12214 43.0088 0.235535 44.1573 0.235535C45.3059 0.235535 46.2596 1.12214 46.3433 2.26762Z"
        fill={fillColor}
      />
      <rect
        x="42.3298"
        y="6.05377"
        width="3.61711"
        height="13.6698"
        fill={fillColor}
      />
      <path
        d="M61.5958 11.3964V19.7236H57.9816V12.163C57.9816 10.2451 56.8857 8.95681 55.2127 8.95681C53.1578 8.95681 51.8171 10.3267 51.8171 13.6698V19.7236H48.2V6.05379H51.8171V7.2867C52.8838 6.21701 54.3091 5.64282 56.1191 5.64282C59.404 5.64282 61.5958 7.99788 61.5958 11.3964Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.9572 5.7769C81.5905 3.30526 77.3876 1.87998 73.7267 2.31135C70.2728 2.71941 66.8568 4.75677 65.0264 7.50239C63.6217 9.52078 63.2019 12.065 63.8839 14.4277C64.7146 17.3423 67.3174 19.4846 70.5177 19.8694C70.8456 19.9088 71.1755 19.9282 71.5058 19.9277C73.6836 19.9696 75.7495 18.9649 77.0611 17.2257C77.7157 17.2257 78.3616 17.197 78.9474 17.1709L78.9586 17.1704H79.116C81.8674 17.0421 85.2922 15.579 85.8955 11.889C86.2873 9.65728 85.5635 7.37497 83.9572 5.7769ZM69.9872 18.7122C67.3144 17.9894 65.4811 15.8937 65.1984 13.2472C65.0003 11.316 65.6137 9.38921 66.8918 7.92793C68.6406 5.85268 71.4883 4.43032 74.3563 4.23795C74.6332 4.22046 74.9072 4.2088 75.1811 4.2088C77.7431 4.2088 80.1361 5.05115 81.8616 6.57261C83.1965 7.75597 84.4498 9.77875 83.9601 11.9589C83.5987 13.5649 82.293 14.8037 80.4625 15.2758C79.6756 15.4582 78.8715 15.5559 78.0638 15.5673C78.4855 14.5184 78.3939 13.3328 77.816 12.3612C77.052 11.0756 75.5834 10.3834 74.1056 10.6123C72.8807 10.7719 71.834 11.5731 71.36 12.7138C70.9874 13.6553 71.1719 14.7261 71.838 15.4886C72.9339 16.8002 74.7294 17.1587 76.5015 17.2228C74.7312 18.8075 72.2702 19.3701 69.9872 18.7122ZM77.347 15.5823L77.3614 15.5819L77.6383 15.579C77.9064 14.7545 77.849 13.8588 77.478 13.0752C77.0592 12.2323 76.2371 11.6633 75.3007 11.5684C75.175 11.5532 75.0484 11.5454 74.9218 11.545C73.9483 11.545 72.864 12.0056 72.5084 12.9761C72.3588 13.347 72.3715 13.7635 72.5434 14.1245C72.8484 14.684 73.3598 15.1023 73.9687 15.2904C75.0252 15.6532 76.206 15.6172 77.347 15.5823Z"
        // brain swirl remains orange
        fill="#EF6A11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.4729 3.54715V3.70454C85.4768 3.73083 85.4847 3.75638 85.4962 3.78033V3.80073H85.2863C85.2697 3.71435 85.2638 3.62624 85.2688 3.53841C85.2736 3.48601 85.2579 3.4338 85.2251 3.39267C85.1844 3.35686 85.1304 3.33993 85.0765 3.34604H84.8637V3.80073H84.6597V2.65526H85.0386C85.1526 2.64772 85.2658 2.67962 85.3592 2.74562C85.4391 2.80861 85.4836 2.90632 85.4787 3.00794C85.4782 3.1102 85.4177 3.20266 85.3242 3.24403C85.3751 3.26863 85.4162 3.30972 85.4408 3.36061C85.465 3.41971 85.4759 3.48338 85.4729 3.54715ZM85.0415 3.16533C85.099 3.1672 85.1558 3.15198 85.2047 3.12161C85.2448 3.0978 85.2692 3.05453 85.2689 3.00794C85.2741 2.95852 85.257 2.90933 85.2222 2.87387C85.1697 2.8422 85.1085 2.82792 85.0473 2.83306H84.8579V3.16533H85.0415Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.1117 2.82549C84.2711 2.4593 84.6364 2.22609 85.0356 2.23557C85.571 2.24356 85.9994 2.6824 85.9945 3.21781C85.9944 3.61719 85.7525 3.97674 85.3826 4.12731C85.0127 4.27787 84.5884 4.18947 84.3095 3.9037C84.0305 3.61792 83.9523 3.19167 84.1117 2.82549ZM85.8006 2.89123C85.6678 2.58933 85.3653 2.39804 85.0356 2.40753C84.8238 2.4106 84.6219 2.4977 84.4743 2.64966C84.3267 2.80162 84.2455 3.006 84.2486 3.21781C84.2488 3.54763 84.4488 3.84445 84.7545 3.96838C85.0601 4.09231 85.4104 4.0186 85.6402 3.782C85.8699 3.54541 85.9334 3.19314 85.8006 2.89123Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};

export default BrainCorpLogo;
