import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UserManagementLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="18"
      cy="12"
      r="4.5"
      stroke="#FF7C00"
      strokeWidth="3"
      fill="none"
    />
    <path
      d="M7.90251 27.5261C9.12653 23.8588 13.0949 21 18 21C22.9051 21 26.8735 23.8588 28.0975 27.5261C28.1709 27.7461 28.1297 27.9273 27.9652 28.1094C27.7748 28.3202 27.4251 28.5 27 28.5H9C8.57489 28.5 8.22525 28.3202 8.03482 28.1094C7.87028 27.9273 7.82908 27.7461 7.90251 27.5261Z"
      stroke="white"
      strokeWidth="3"
      fill="none"
    />
  </SvgIcon>
);

export default UserManagementLogo;
