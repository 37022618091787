import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  styled,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import { AppName } from '../../utils/types';
import { APP_CARDS_META } from '../../utils/consts';
import { AppCardButton } from '../../shared/styled';
import MobileQRCode from './mobile-qr-code';

const HAS_ACCESS = 'Open';
const REQUEST_ACCESS = 'Request Access';

// Type guard function
const isMobileApp = (
  appName: AppName
): appName is Extract<AppName, 'mobile' | 'mobileTennant'> => {
  return appName === 'mobile' || appName === 'mobileTennant';
};

const AppCard = ({
  appName,
  hasAccess,
}: {
  appName: AppName;
  hasAccess?: boolean;
}) => {
  const { logo, name, description, destination } = APP_CARDS_META[appName];
  const buttonText = hasAccess ? HAS_ACCESS : REQUEST_ACCESS;
  const theme = useTheme();

  return (
    <StyledAppCard data-testid={`${appName}-appCard`}>
      <CardHeader
        avatar={logo}
        title={<Typography variant="h6">{name}</Typography>}
        disableTypography
        sx={{
          padding: theme.spacing(3, 3, 2, 3),
          '.MuiCardHeader-avatar': { marginRight: 1 },
        }}
      />
      <CardContent sx={{ padding: theme.spacing(0, 3) }}>
        {/* use minHeight to force all elements to be, at minimum, "2 lines of text tall" */}
        <Typography variant="body2" sx={{ minHeight: 41 }}>
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={{ padding: theme.spacing(3) }}>
        {isMobileApp(appName) ? (
          <MobileQRCode appName={appName} buttonText={buttonText} />
        ) : (
          <AppCardButton
            data-testid={`${appName}-button`}
            color="primary"
            variant="contained"
            href={destination}
            target="_blank"
          >
            {buttonText}
          </AppCardButton>
        )}
      </CardActions>
    </StyledAppCard>
  );
};

const StyledAppCard = styled(Card)(() => ({
  // IMPORTANT: Width is determined by parent MUI Grid
  minHeight: '196px',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  borderRadius: '8px',
}));

const AppCardMemo = memo(AppCard);

export default AppCardMemo;
