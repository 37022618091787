import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MobileTennantLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5 4.5H13.5C11.8431 4.5 10.5 5.84315 10.5 7.5V25.5H7.5V7.5C7.5 4.18629 10.1863 1.5 13.5 1.5H22.5C25.8137 1.5 28.5 4.18629 28.5 7.5V28.5C28.5 31.8137 25.8137 34.5 22.5 34.5H16.5V31.5H22.5C24.1569 31.5 25.5 30.1569 25.5 28.5V7.5C25.5 5.84315 24.1569 4.5 22.5 4.5Z"
      fill="#4299C3"
    />
    <path
      d="M7.5 27V28.5C7.5 31.8137 10.1863 34.5 13.5 34.5H15V31.5H13.5C11.8431 31.5 10.5 30.1569 10.5 28.5V27H7.5Z"
      fill="white"
    />
  </SvgIcon>
);

export default MobileTennantLogo;
