import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const VirtualToursLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 30C17.6774 30 16.5778 29.6605 15.38 27.265C14.2719 25.0489 13.5 21.7774 13.5 18C13.5 14.2226 14.2719 10.9511 15.38 8.73504C16.5778 6.33949 17.6774 6 18 6C18.3226 6 19.4222 6.33949 20.62 8.73504C21.7281 10.9511 22.5 14.2226 22.5 18C22.5 18.9026 22.4559 19.7763 22.3737 20.6136C23.5226 20.4017 24.5567 20.1135 25.448 19.7765C25.4823 19.1939 25.5 18.6011 25.5 18C25.5 9.71573 22.1421 3 18 3C13.8579 3 10.5 9.71573 10.5 18C10.5 26.2843 13.8579 33 18 33C20.5943 33 22.8809 30.3656 24.2276 26.3614C23.138 26.5953 21.9922 26.7687 20.8064 26.8753C20.7454 27.0085 20.6833 27.1385 20.62 27.265C19.4222 29.6605 18.3226 30 18 30Z"
      fill="#FF7C00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 18C6 18.3226 6.33949 19.4222 8.73504 20.62C10.9511 21.7281 14.2226 22.5 18 22.5C21.7774 22.5 25.0489 21.7281 27.265 20.62C29.6605 19.4222 30 18.3226 30 18C30 17.6774 29.6605 16.5778 27.265 15.38C25.0489 14.2719 21.7774 13.5 18 13.5C17.0974 13.5 16.2237 13.5441 15.3864 13.6263C15.5983 12.4774 15.8865 11.4433 16.2235 10.552C16.8061 10.5177 17.3989 10.5 18 10.5C26.2843 10.5 33 13.8579 33 18C33 22.1421 26.2843 25.5 18 25.5C9.71573 25.5 3 22.1421 3 18C3 15.4057 5.63439 13.1191 9.63859 11.7724C9.4047 12.862 9.23133 14.0078 9.12471 15.1936C8.99145 15.2546 8.86153 15.3167 8.73504 15.38C6.33949 16.5778 6 17.6774 6 18Z"
      fill="white"
    />
  </SvgIcon>
);

export default VirtualToursLogo;
