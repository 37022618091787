import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';
import Header from './header';
import { MAX_VIEW_WIDTH, MIN_VIEW_WIDTH } from '../../utils/consts';
import { FCC } from '../../utils/types';
import Footer from './footer';
import { rootAndHeaderStyles } from '../styled';

// root component contains shared layout for all child routes
const Layout: FCC = () => {
  return (
    <RootContainer>
      <BodyContainer>
        <Header />
        <Outlet />
        <Footer />
      </BodyContainer>
    </RootContainer>
  );
};

// IMPORTANT: These styles will not affect the `position: fixed` header
const RootContainer = styled('div', { name: 'RootContainer' })(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  ...rootAndHeaderStyles(theme),
}));

const BodyContainer = styled('div', { name: 'BodyContainer' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  minWidth: MIN_VIEW_WIDTH,
  maxWidth: MAX_VIEW_WIDTH,
  padding: theme.spacing(0, 0, 5),
  margin: 0,
}));

export default Layout;
