import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { subSeconds } from 'date-fns';
import { DecodedIdToken, GcipToken, ITenantInfo, Module } from './auth-types';
import {
  API_KEY,
  EMAIL_KEY,
  ENV_DOMAIN,
  ID_TOKEN_KEY,
  REFRESH_KEY,
  REVISIT_KEY,
} from './auth-consts';

export const isTokenExpired = (idToken: string): boolean => {
  try {
    const decodedToken = jwt_decode(idToken) as DecodedIdToken;
    const expiresDate = new Date(decodedToken.exp * 1000);
    const twoSecondsAgo = subSeconds(new Date(), 2);
    return expiresDate <= twoSecondsAgo;
  } catch (err) {
    console.error('idToken is malformed for expiry decode', err);
    return true;
  }
};

// Parse email from token
export const getEmailFromIDToken = (idToken: string): string => {
  try {
    const decodedToken = jwt_decode(idToken) as DecodedIdToken;
    return decodedToken.email;
  } catch (err) {
    console.error('idToken is malformed for email decode', err);
    return '';
  }
};

export const refreshIdToken = async (
  refreshToken: string,
  gcipApiKey: string
): Promise<GcipToken> => {
  try {
    const bearerToken = await axios.post<GcipToken>(
      `https://securetoken.googleapis.com/v1/token?key=${gcipApiKey}`,
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }
    );
    return bearerToken.data;
  } catch (err: any) {
    console.error('Unable to refresh token', err);
    if (
      err?.code === 'ERR_BAD_REQUEST' ||
      err?.message?.includes('400') ||
      err?.message?.includes('403') ||
      err?.error?.message === 'TOKEN_EXPIRED' ||
      err?.error?.message === 'MISSING_REFRESH_TOKEN'
    ) {
      // refresh token has been revoked. remove tokens from storage and reload page
      sessionStorage.removeItem(ID_TOKEN_KEY);
      sessionStorage.removeItem(API_KEY);
      sessionStorage.removeItem(REFRESH_KEY);
      // don't remove EMAIL_ID_KEY, so we can use it to pre-populate the email query param
      window.location.reload();
    }
    return {
      expires_in: '',
      token_type: '',
      refresh_token: refreshToken,
      id_token: '',
      user_id: '',
      project_id: '',
      err: err?.code || '',
    };
  }
};

export const saveTokensToStorage = (
  idToken: string,
  refreshToken: string,
  apiKey?: string
) => {
  sessionStorage.setItem(ID_TOKEN_KEY, idToken);
  sessionStorage.setItem(REFRESH_KEY, refreshToken);
  if (apiKey) {
    sessionStorage.setItem(API_KEY, apiKey);
  }
  return;
};

export const getUserFromToken = (
  idToken: string
): { tenantId: string; uid: string; email: string } => {
  const decodedToken = jwt_decode(idToken) as DecodedIdToken;
  return {
    tenantId: decodedToken.firebase.tenant,
    uid: decodedToken.user_id,
    email: decodedToken.email,
  };
};

export const getOrRefreshIdToken = async (): Promise<string> => {
  let token = window.sessionStorage.getItem(ID_TOKEN_KEY) || '';

  // add email to session storage IFF SSO user
  if (token) {
    const email = getEmailFromIDToken(token);
    if (email) {
      window.sessionStorage.setItem(EMAIL_KEY, email);
    }
  }

  if (!token || isTokenExpired(token)) {
    const refreshToken = window.sessionStorage.getItem(REFRESH_KEY) || '';
    const apiKey = window.sessionStorage.getItem(API_KEY) || '';
    const { id_token: newIdToken, refresh_token: newRefreshToken } =
      await refreshIdToken(refreshToken, apiKey);
    saveTokensToStorage(newIdToken, newRefreshToken);
    token = newIdToken;
  }
  return token;
};

export const lookupTenant = async (
  domain: string
): Promise<ITenantInfo | null> => {
  if (!domain) {
    return null;
  }
  const discoveryHostname = getDiscoveryHostname();
  const discoveryURL = `https://${discoveryHostname}/tenant?domain=${domain.toLowerCase()}`;

  const req = await fetch(discoveryURL);
  if (req.status !== 200) {
    return null;
  }
  return (await req.json()) as ITenantInfo;
};

export const getDiscoveryHostname = (): string => {
  const hostname = window.location.hostname;
  if (hostname.startsWith('login.')) {
    return `functions.${hostname.replace('login.', '')}/discovery`;
  }
  return `functions.brainos.${ENV_DOMAIN}/discovery`;
};

export const handleRevisit = (
  pathname: string,
  search: string,
  redirect: string | null
): void => {
  const badUrls = ['/login', '/logout'];
  if (
    redirect &&
    badUrls.some((badUrl: string) => redirect!.includes(badUrl))
  ) {
    localStorage.removeItem(REVISIT_KEY);
  }
  const isValidRedirect =
    pathname &&
    pathname !== '/' &&
    !badUrls.some((badUrl: string) => pathname.includes(badUrl));
  isValidRedirect && localStorage.setItem(REVISIT_KEY, `${pathname}${search}`);
};

// compare modules with user permissions and return only the modules that the user is allowed to see
export const getAllowedModules = (
  modules: Module[],
  permissions: string[]
): Module[] => {
  const isIncludedInUserPermission = (currentValue: string) =>
    permissions.includes(currentValue);
  return modules.reduce((result: Module[], module: Module) => {
    const { permissions: modulePermissions } = module;
    const isAllowed = modulePermissions.every(isIncludedInUserPermission);
    if (isAllowed) {
      result.push(module);
    }
    return result;
  }, []);
};
