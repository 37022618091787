import { AxiosInstance } from 'axios';
import { TenantsApiResponse } from './types';

const TENANTS_ENDPOINT = 'v1/iam/tenants';

/**
 * Lists all (visible to user) tenant id's and display names
 */
export const listTenants = async (
  brainOsApi: AxiosInstance
): Promise<TenantsApiResponse | null> => {
  try {
    const result = await brainOsApi.get<TenantsApiResponse>(TENANTS_ENDPOINT);
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
