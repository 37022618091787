import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PortalLogo = (props: SvgIconProps) => (
  <SvgIcon
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="31.5"
      y="6"
      width="19.5"
      height="27"
      rx="4.5"
      transform="rotate(90 31.5 6)"
      stroke="white"
      strokeWidth="3"
      fill="none"
    />
    <path
      d="M13.5 30H22.5"
      stroke="#FF7C00"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default PortalLogo;
