import { useEffect, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import AppCardMemo from './app-card';
import { AppName, OemPartnerNames } from '../../utils/types';
import { APP_CARDS_META, APP_NAMES } from '../../utils/consts';
import Billboard from './billboard';
import { useAuth } from '../../auth/auth-context';
import useBrainOSApi from '../../api/use-brainos-api';
import { styled } from '@mui/material/styles';
import { listTenants } from '../../api/api-utils';

// Iterate through an array of permissions and return an array of apps that the user has access to
const getAppsUserCanAccess = (
  permissions: string[],
  isInternal?: boolean
): AppName[] => {
  const apps: AppName[] = [];
  APP_NAMES.forEach(appName => {
    const { permission } = APP_CARDS_META[appName];
    if (permission === true) {
      apps.push(appName);
    } else if (typeof permission === 'string') {
      if (permissions.includes(permission)) {
        apps.push(appName);
      }
    }
  });

  // only show health app for internal tenants for now
  // add it to the end of the array
  if (isInternal) {
    apps.push('healthStatus');
  }

  apps.sort();

  return apps;
};

// todo: memoize?
const renderAppsGrid = (
  apps: AppName[],
  columns: number,
  hasAccess?: boolean
) => {
  const rowAccumulator = apps.map(appName => {
    return (
      <Grid item xs={12 / columns} key={appName}>
        <AppCardMemo appName={appName} hasAccess={hasAccess} />
      </Grid>
    );
  });

  return (
    <Grid container spacing={3}>
      {rowAccumulator}
    </Grid>
  );
};

const Home = () => {
  const [
    {
      user: { tenantId },
      permissions,
    },
  ] = useAuth();
  const { brainOsApiInstance } = useBrainOSApi();
  const [oemPartners, setOemPartners] = useState<OemPartnerNames[]>([]);
  const [
    // cardColumns MUST be a factor of 12
    cardColumns,
    setCardColumns,
  ] = useState(3);

  // custom media queries for cardColumns
  const large = useMediaQuery('(min-width: 1100px)');
  const medium = useMediaQuery('(min-width: 750px)');

  useEffect(() => {
    const getOemLogos = async () => {
      const tenants = await listTenants(brainOsApiInstance);
      if (!tenants) return;

      const targetTenant = tenants.tenants?.find(t => t.tenantId === tenantId);
      const oems = targetTenant?.oemPartners ?? [];

      oems.sort();
      setOemPartners(oems);
    };

    getOemLogos();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [tenantId]);

  useEffect(() => {
    if (large) {
      setCardColumns(3);
    } else if (medium) {
      setCardColumns(2);
    } else {
      setCardColumns(1);
    }
  }, [medium, large]);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" sx={{ marginBottom: 4 }}>
        Welcome! Explore your apps to get started.
      </Typography>

      {/* "Your Apps" section where user sees apps they already have access to */}
      <Typography variant="h5" sx={{ marginTop: 5 }}>
        Your Apps
      </Typography>

      <StyledCardContainer sx={{ marginBottom: 3 }}>
        {renderAppsGrid(
          getAppsUserCanAccess(permissions, tenantId.includes('braincorp')),
          cardColumns,
          true
        )}
      </StyledCardContainer>

      {/* TODO: "Explore Apps" section where user sees apps they can request access to */}

      <Billboard oemsToDisplay={oemPartners} />
    </Box>
  );
};

const StyledCardContainer = styled(Box)(({ theme }) => ({
  marginTop: 3,
  flexWrap: 'wrap',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '1rem',
  [theme.breakpoints.up('lg')]: {},
}));

export default Home;
